.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: #666e7c !important;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 100px;
}

.jobPostCkEditor .ql-editor {
  min-height: 100px;
  border: none;
  resize: none;
}

.ckEditorPreview .ql-toolbar {
  display: none;
}
.ckEditorPreview .ql-editor {
  border: 1.5px solid #a2a0a0;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  background-color: darkgray;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  background-color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  --tableComRowBg: #fbf8f6;
  --tableComRowStart: #ffb19d;

  /* -----------------icon colors--------------------- */
  --icon-green: #68b34e;
  --icon-greenlight: #e5fff1;
  --icon-dark: #f69200;
  --icon-warning: #ffa800;
  --icon-warninglight: #fffbef;
  --icon-error: #f46a6a;
  --icon-errorlight: #fce8ea;
  --icon-sidebar: #f4efe8;
  --icon-sidebar1: #ffffff;
  --icon-fieldErr: #f83630;
  --icon-blue: #6b56f6;
  --icon-skyblue: #4d7cf8;
  /* -----------------icon colors--------------------- */

  --text-head: "#323139";
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
